import { render, staticRenderFns } from "./most-active-user-section.vue?vue&type=template&id=357f3264&scoped=true&"
import script from "./most-active-user-section.vue?vue&type=script&lang=ts&"
export * from "./most-active-user-section.vue?vue&type=script&lang=ts&"
import style0 from "./most-active-user-section.vue?vue&type=style&index=0&id=357f3264&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "357f3264",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VImg } from 'vuetify/lib/components/VImg';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VImg,VSheet,VSpacer})
