















































import { Observer } from 'mobx-vue'
import { Component, Vue } from 'vue-property-decorator'
import { userActionController } from './most-active-user-section-controller'
import { walletStore } from '@/stores/wallet-store'

@Observer
@Component({
  components: {
    'follow-button': () => import('../follow-button.vue'),
  },
})
export default class extends Vue {
  controller = userActionController
  walletStore = walletStore
}
